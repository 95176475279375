import React, { useEffect, useState, useContext } from 'react'

import { getComparator } from '@components/common/TableHeadSort'
import { useAsyncRequest } from '@/utils/actionWrapper'
import platformApi from '@/api/platforms'
import { AuthContext } from '@/provider/KeycloakProvider'
import { CircularProgress } from '@mui/material'

const HomePage = () => {
  const { token } = useContext(AuthContext)
  const { get } = platformApi(token)
  const { actionWrapper } = useAsyncRequest()

  const [platforms, setPlatforms] = useState([])

  useEffect(() => {
    async function fetchData() {
      if (token) {
        const platforms = await actionWrapper(() => get())
        setPlatforms(platforms)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])
  return (
    <>
      {platforms.length > 0 ? (
        <div className="grid grid-cols-1 gap-20 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {platforms
            .sort(getComparator('desc', 'dashboardPosition'))
            .map((platform, index) => (
              <a
                href={platform?.link}
                target="_blank"
                key={platform.id}
                className=" text-[#003a5d]"
                rel="noreferrer"
              >
                {platform?.pictureUrl && (
                  <div className=' h-[150px] overflow-hidden'>
                    <img src={platform?.pictureUrl} alt={platform?.name} />

                  </div>
                )}
                <h1 className=" text-[#003a5d] font-bold">{platform?.name}</h1>
                {platform?.description && (
                  <div className='text-container'>
                    <p className=" text-[#003a5d]">{platform?.description}</p>
                  </div>
                )}
              </a>
            ))}
        </div>
      ) : (
        <div className="w-full flex justify-center h-100">
          <CircularProgress />
        </div>
      )}
    </>
  )
}

export default HomePage
