import React, { useContext, useEffect, useState } from 'react'
import { Button, Table, TableCell, TableRow } from '@mui/material'
import TableHeadSortable from '@components/common/TableHeadSort'
import { getComparator } from '@components/common/TableHeadSort'
import ConfirmDialog from '@components/common/ConfirmDialog'
import { AuthContext } from '@/provider/KeycloakProvider'
import { useAsyncRequest } from '@/utils/actionWrapper'
import platformApi from '@/api/platforms'

const PlatformIndex = () => {
  const { token } = useContext(AuthContext)
  const platform = platformApi(token)
  const { actionWrapper } = useAsyncRequest()

  const [platforms, setPlatforms] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('dashboardPosition')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const headCells = [
    {
      id: 'name',
      label: 'Nom de la plateforme',
    },
    {
      id: 'link',
      label: 'Lien',
    },
    {
      id: 'imageBytes',
      label: 'Image',
    },
    {
      id: 'description',
      label: 'Description',
    },
    {
      id: 'dashboardPosition',
      label: ' Position sur le tableau de bord',
    },
    {
      id: 'empty',
      isEmpty: true,
    },
  ]
  useEffect(() => {
    async function fetchData() {
      if (token) {
        const platforms = await actionWrapper(() => platform.get())
        setPlatforms(platforms)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const onDelete = async (id) => {
    await actionWrapper(() => platform.delete(id))
    setPlatforms(platforms.filter(x => x.id !== id))
  }
  return (
    <>
      <ConfirmDialog
        open={open}
        setopen={setOpen}
        callback={() => onDelete(selectedItem)}
      />
      <div className=" w-full">
        <div className="px-4 md:px-10 py-4 md:py-7">
          <p className="focus:outline-none text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
          Plateformes
          </p>
        </div>

        <div className="bg-white py-4 md:py-7 px-4 md:px-8 xl:px-10">
          <div className="sm:flex items-center justify-between">
            <a
              href="/admin/new"
              className="text-sm font-medium leading-none text-white"
            >
              <button className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 mt-4 sm:mt-0 inline-flex items-start justify-start px-6 py-3 bg-[#003a5d] hover:bg-indigo-600 focus:outline-none rounded">
              Ajout d’une nouvelle plateforme
              </button>
            </a>
          </div>
          <div className="overflow-x-auto my-5">
            <Table className="min-w-full bg-white border border-gray-300">
              <TableHeadSortable
                onRequestSort={handleRequestSort}
                order={order}
                headCells={headCells}
                orderBy={orderBy}
              />
              <tbody>
                {platforms.length > 0 ? (
                  platforms
                    .sort(getComparator(order, orderBy))
                    .map((platform, index) => (
                      <tr
                        key={index}
                        className={
                          index % 2 === 0 ? 'bg-gray-100' : 'bg-gray-200'
                        }
                      >
                        <td className="border px-4 py-2">{platform?.name}</td>
                        <td className="border px-4 py-2">
                          <a
                            href={platform?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            {platform?.link}
                          </a>
                        </td>
                        <td className="border px-4 py-2">
                          {platform?.pictureUrl ? (
                            <img
                              src={platform?.pictureUrl}
                              alt="Thumbnail"
                              className="h-10 w-10 object-cover"
                            />
                          ) : (
                            <p>Aucune image</p>
                          )}
                        </td>
                        <td className="border px-4 py-2">
                          {platform?.description}
                        </td>
                        <td className="border px-4 py-2">
                          {platform?.dashboardPosition}
                        </td>
                        <TableCell scope="row" align="right">
                          <Button href={`/admin/edit/${platform.id}`}>
                          Modifier
                          </Button>
                          <Button
                            onClick={() => {
                              setSelectedItem(platform.id)
                              setOpen(true)
                            }}
                            color="error"
                          >
                            Supprimer
                          </Button>
                        </TableCell>
                      </tr>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headCells.length} align="center">
                      No data to show
                    </TableCell>
                  </TableRow>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlatformIndex
